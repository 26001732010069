<div class="mode-bar">
  <button mat-button class="today-button" (click)="gotoToday()">
    <mat-icon>today</mat-icon> {{ 'today' | i18nextCap }}
  </button>
  <button mat-icon-button (click)="fastBack()">
    <mat-icon>fast_rewind</mat-icon>
  </button>
  <button mat-icon-button (click)="back()">
    <mat-icon>skip_previous</mat-icon>
  </button>
  <button mat-button [matMenuTriggerFor]="modeMenu">
    {{view | i18nextCap}} ▼
  </button>
  <button mat-icon-button (click)="forward()">
    <mat-icon>skip_next</mat-icon>
  </button>
  <button mat-icon-button (click)="fastForward()">
    <mat-icon>fast_forward</mat-icon>
  </button>
  <span class="calendar-title">{{headerText}}</span>
</div>
<div class="fill-space">
  <full-calendar [options]="options"> </full-calendar>
</div>
<div class="popup" #popup>
  <ng-container
    *ngIf="(hoveredEvent$ | async) != null"
    [ngTemplateOutlet]="popupTemplate"
    [ngTemplateOutletContext]="{$implicit: (hoveredEvent$ | async)}"
  >
  </ng-container>
</div>
<mat-menu #modeMenu class="calendar-view-menu">
  <div></div>
  <button mat-menu-item (click)="switchView('dayGridMonth')">
    {{'month' | i18nextCap}}
  </button>
  <button mat-menu-item (click)="switchView('timeGridWeek')">
    {{'week' | i18nextCap}}
  </button>
  <button mat-menu-item (click)="switchView('timeGridDay')">
    {{'day' | i18nextCap}}
  </button>
  <hr />
  <mat-slide-toggle [(ngModel)]="options.weekends">
    {{'showWeekends' | i18nextCap}}
  </mat-slide-toggle>
</mat-menu>
